import React from "react";
import { Modal } from "react-bootstrap";

const Navbar = ({ show, setShow, lifes, setPause }) => {
  const handleClose = () => {
    setPause(false)
    setShow(false);
    const date = new Date();
    localStorage.setItem("firstTimePlayer", date);
  };
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <header>
        <nav className="bg-black">
          <div className=" d-flex ">
            <div className="m-auto nav-logo align-items-center justify-content-center nav">
              <img src="/images/logo.png" alt="" />
            </div>
            <div className="ml-auto me-3 mt-2 pt-1 d-none d-md-flex align-items-center gap-3  position-absolute top-0 end-0">
              <button type="button" className="btn p-0" onClick={handleShow}>
                <img src="/images/Question.png" alt="" />
              </button>
              <button
                type="button"
                className="btn btn-dark border border-3 rounded-circle py-1 fw-bold"
              >
                {lifes}
              </button>
              {/* <button className="button1">
              <img src="/images/Frame 2.png" alt="" />
            </button>
            <button className="button1">
              <img src="/images/Frame 3.png" alt="" />
            </button> */}
            </div>
          </div>
        </nav>
      </header>

      <Modal
        centered
        dialogClassName="modal-width"
        contentClassName=" text-white rounded-3"
        show={show}
        onHide={handleClose}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title className="fw-bolder">HOW TO PLAY</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <div className=" text-white curve-modal w-100 bg-dark-colour pb-4">
            <div
              className="bg-cover-2 "
              style={{ backgroundImage: "url(/images/bg2.png)" }}
            ></div>
            <div className="position-absolute pt-4 mt-1 top-0 end-0 start-0 mx-4">
              <h2 className="text-center m-auto fs-1 fw-bold pt-3 ">
                HOW TO PLAY
              </h2>
              <p className="text-center fs-5 mx-2 mx-lg-5 fw-normal">
                Guess the word or sequence of words without losing more than
                your permitted lives.
              </p>
            </div>
            <div className="mx-2 mx-lg-5 mt-4 text-center  examples px-1 py-4">
              <h5>
                Each letter is represented by their corresponding numerical
                position in the alphabet:
              </h5>
              <div className="row row-cols-lg-4 row-cols-2 text-center align-items-center  m-0 p-0 mt-4">
                <div className="col">
                  <div className="d-flex align-items-center justify-content-lg-center">
                    <h2 className="example-boxx px-3 pb-2 pt-1">A</h2>
                    <h2 className="mx-1 fw-bold"> = 1</h2>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center justify-content-lg-center">
                    <h2 className="example-boxx px-3 pb-2 pt-1">B</h2>
                    <h2 className="mx-1 fw-bold"> = 2</h2>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center justify-content-lg-center">
                    <h2 className="example-boxx px-3 pb-2 pt-1">C</h2>
                    <h2 className="mx-1 fw-bold"> = 3</h2>
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex align-items-center justify-content-lg-center">
                    <h2 className="example-boxx px-3 pb-2 pt-1">Z</h2>
                    <h2 className="mx-1 fw-bold"> = 26</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-2 mx-lg-5 mt-4 text-center  examples px-1 py-4">
              <h5>
                The letter will go green when correct and red when incorrect.
              </h5>
              <div className="row row-cols-1 row-cols-lg-2 mx-2 mx-lg-5 mt-4 ro">
                <div className="col">
                  <div className="d-flex py-4 px-5 align-items-center justify-content-center  bg-dark-colour rounded-1">
                    <div>
                      {" "}
                      <h2 className="example-box-green px-3 pb-2 pt-1 mx-1">
                        B
                      </h2>
                      <p className="mx-2 ">2</p>
                    </div>
                    <div>
                      {" "}
                      <h2 className="example-box-green px-3 pb-2 pt-1 mx-1">
                        A
                      </h2>
                      <p className="mx-2 ">1</p>
                    </div>
                    <div>
                      {" "}
                      <h2 className="example-box-green px-3 pb-2 pt-1 mx-1">
                        B
                      </h2>
                      <p className="mx-2 ">2</p>
                    </div>
                    <div>
                      {" "}
                      <h2 className="example-box-green px-3 pb-2 pt-1 mx-1">
                        Y
                      </h2>
                      <p className="mx-2 ">25</p>
                    </div>

                    {/* <h2 className="example-box-green px-3 pb-2 pt-1 mx-1">A</h2>
                    <h2 className="example-box-green px-3 pb-2 pt-1 mx-1">A</h2>
                    <h2 className="example-box-green px-3 pb-2 pt-1 mx-1">A</h2> */}
                    {/* <p className="mx-2 ">Correct Letter</p> */}
                  </div>
                </div>
                <div className="col">
                  <div className="d-flex  py-4 px-5 align-items-center bg-dark-colour  rounded-1">
                    {/* <h2 className="example-box-red px-3 pb-2 pt-1">B</h2> */}
                    <p>
                      The number beneath the letter is the position in the
                      alphabet, for example in this example, 2=B, 1=A, 2=B, 25=Y
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center mx-0 mx-lg-5 px-lg-5">
              <p className="text-center mt-4 fs-5 fw-light">
                The aim of the game is to guess the word or sequence of words in
                the quickest time possible.
              </p>
              <button
                onClick={handleClose}
                className="text-warning   py-2 mb-2 w-25"
              >
                Got it
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
