import React, { useEffect, useState } from "react";
import AlphabetKeyboard from "./keyboard/AlphabetKeyboard";
import Navbar from "./Navbar";
import Timer from "./Timer";

const Layout = ({ config }) => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [show, setShow] = useState(false);
  const [howToModal, setHowToModal] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [lifes, setLifes] = useState(null);
  const [category, setCategory] = useState(null);
  const [lost, setLost] = useState(false);
  const [hints, setHints] = useState(null);
  const [word, setWord] = useState(null);
  const [local, setLocal] = useState(false);
  const [pause, setPause] = useState(false);
  const [wordLength, setLength] = useState(null);
  // const word = process.env.REACT_APP_WORD;

  useEffect(() => {
    if (config !== null) {
      setHints(config?.hints);
      setWord(config?.word);
      setLifes(config?.lives);
      setCategory(config?.category);
    }
  }, [config]);
  useEffect(() => {
    if (word) {
      let completeCheck = JSON.parse(localStorage.getItem("completed"));

      if (completeCheck) {
        // setHowToModal(false);
        const endDate = new Date();
        const startDate = new Date(completeCheck.time);
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();
        const timeDiff = endTime - startTime;
        const hoursDiff = timeDiff / (1000 * 60 * 60);

        if (hoursDiff >= 24 || completeCheck.word !== word) {
          setCompleted(false);
          localStorage.removeItem("completed");
        } else {
          setCompleted(true);
          setLost(completeCheck.lost);
          setLocal(true);
        }
      }
    }
  }, [word]);

  useEffect(() => {
    const firstTime = localStorage.getItem("firstTimePlayer");

    if (firstTime === null) {
      if (completed !== true) {
        setHowToModal(true);
      }
    } else {
      const endDate = new Date();
      const startDate = new Date(firstTime);
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const timeDiff = endTime - startTime;
      const hoursDiff = timeDiff / (1000 * 60 * 60);
      if (hoursDiff >= 336) {
        localStorage.removeItem("firstTimePlayer");
      }
      setHowToModal(false);
    }
  }, [completed]);

  useEffect(() => {
    if (lifes === 0 || lifes < 0) {
      setCompleted(true);
      setLost(true);
    }
  }, [lifes]);
  return (
    <div className="Layout">
      <div className="bg-black">
        <Navbar
          show={howToModal}
          setShow={setHowToModal}
          lifes={lifes}
          setPause={setPause}
        />
        <div className="bg-black Customcontainer">
          <Timer
            seconds={seconds}
            minutes={minutes}
            setSeconds={setSeconds}
            setMinutes={setMinutes}
            lost={lost}
            show={show}
            setShow={setShow}
            completed={completed}
            howToModal={howToModal}
            setHints={setHints}
            setLifes={setLifes}
            showClue={config?.clue}
            category={category}
            lifes={lifes}
            setHowToModal={setHowToModal}
            setPause={setPause}
            pause={pause}
            wordLength={wordLength}
            hints={hints}
          />

          <AlphabetKeyboard
            seconds={seconds}
            minutes={minutes}
            word={word}
            lost={lost}
            setShow={setShow}
            setCompleted={setCompleted}
            completed={completed}
            setLifes={setLifes}
            hints={hints}
            local={local}
            setLength={setLength}
          />
        </div>
      </div>
    </div>
  );
};

export default Layout;
