import { string } from "i/lib/util";
import React, { useEffect, useState } from "react";
import { winLogAdd } from "../../helpers/helpers";

const Gameinput1 = ({
  value,

  handleKeyDown,
  setShow,
  setInputValue,
  setIndexForClick,
  handleButtonClick,
  setCompleted,
  completed,
  setLifes,
  lost,
  hints,
  word,
  local,
  seconds,
  minutes,
  removeAlphabet,
  checkArray,
  setCheckArray,
  setLength,
}) => {
  const [stringArray, setStringArray] = useState(null);
  // const [checkArray, setCheckArray] = useState(null);
  const [spacesCount, setSpacesCount] = useState(null);
  const [generatedHints, setGeneratedHints] = useState();

  useEffect(() => {
    if (word !== null) {
      setStringArray(Array.from(word));
    }
  }, [word]);

  useEffect(() => {
    const indexes = [];
    if (stringArray !== null) {
      for (let i = 0; i < Array.from(word).length; i++) {
        if (stringArray[i] === " ") {
          indexes.push(i);
        }
      }
      const modifiedNumbers = indexes.map((num, index) => num - (index + 1));
      setSpacesCount(modifiedNumbers);
      let arr = stringArray.filter(function (element) {
        return element.trim() !== "";
      });

      const numbers = [];

      while (numbers.length < hints) {
        const randomNumber = Math.floor(Math.random() * arr.length);

        if (!numbers.includes(randomNumber)) {
          numbers.push(randomNumber);
        }
      }
      setGeneratedHints(numbers);
      setCheckArray(arr);
      setLength(arr.length);
      if (completed === true) {
        setInputValue(arr);
      } else {
        let array = [];
        arr.forEach((element) => array.push(""));

        setInputValue(array);
      }
    }
  }, [stringArray, completed]);

  useEffect(() => {
    if (generatedHints && generatedHints?.length !== hints) {
      let existingHints = generatedHints;
      while (existingHints?.length !== hints) {
        const randomNumber = Math.floor(Math.random() * checkArray.length);

        if (!existingHints.includes(randomNumber)) {
          existingHints.push(randomNumber);
        }
      }
      setGeneratedHints([...existingHints]);
    }
  }, [hints]);

  const getIndex = (index) => {
    if (spacesCount.includes(index)) {
      const foundIndex = spacesCount.indexOf(index);
      return { found: true, index: foundIndex };
    } else {
      return { found: false };
    }
  };

  useEffect(() => {
    if (value !== null && checkArray !== null) {
      if (
        JSON.stringify(value).toLowerCase() ===
          JSON.stringify(checkArray).toLowerCase() &&
        lost === false
      ) {
        // if (local === false) {

        // }

        setCompleted(true);
        const date = new Date();
        const data = {
          word: word,
          time: date,
          lost: lost,
        };

        localStorage.setItem("completed", JSON.stringify(data));
      } else if (lost === true) {
        setCompleted(true);
        const date = new Date();
        const data = {
          word: word,
          time: date,
          lost: lost,
        };

        localStorage.setItem("completed", JSON.stringify(data));
      }
    }
  }, [value, checkArray, completed, local]);

  useEffect(() => {
    if (local === false && lost === false && completed === true) {
      console.log("running for local");
      setShow(true);
      let time = `${minutes < 10 ? "0" + minutes : minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`;
      winLogAdd(time);
    }
  }, [completed, lost]);

  useEffect(() => {
    if (completed === true && checkArray !== null) {
      setInputValue(checkArray);
    }
  }, [completed, checkArray]);

  const nextInput = (event, index) => {
    if (event.target.value.length === 1 && index < value.length - 1) {
      if (event.key === "backspace") {
        console.log("backspace triggered");
        const nextInput = document.getElementsByName(`input${index - 2}`)[0];
        nextInput.focus();
      } else {
        const nextInput = document.getElementsByName(`input${index + 2}`)[0];
        nextInput?.focus();
      }
    }
  };

  useEffect(() => {
    if (checkArray) {
      const nextInput = document.getElementsByName(`input${0 + 1}`)[0];
      nextInput?.focus();
    }
  }, [checkArray]);

  const updateLifes = (index, letter) => {
    if (letter.toLowerCase() !== checkArray[index].toLowerCase()) {
      console.log("subtracting one");
      setLifes((prevCount) => prevCount - 1);
    }
  };

  // const handleFocus = (event) => {
  //   event.target.style.backgroundColor = "#495057"; // Change the border color to blue when focused
  // };

  return (
    <>
      <div className="bg-black">
        <form className="input-game">
          <div className=" justify-content-center flex-wrap gaps">
            {/* input 1 */}
            <div className="mb-5 pb-3 text-center">
              {checkArray !== null &&
                checkArray.map((word, index) => (
                  <>
                    <div className={`text-center d-inline-block`}>
                      <input
                        inputMode="none"
                        disabled={completed === true ? true : false}
                        onChange={(e) => {
                          console.log("e", e.key, e.keyCode);
                          handleButtonClick(e.target.value, false);
                          nextInput(e, index);
                          updateLifes(index, e.target.value);
                        }}
                        onClick={() => setIndexForClick(index)}
                        style={{
                          textTransform: "uppercase",
                          caretColor: "transparent",
                          // backgroundColor: "#495057",
                        }}
                        className={
                          lost === true
                            ? "input-bg-red"
                            : value !== null &&
                              value[index] !== undefined &&
                              value[index] !== ""
                            ? value[index].toLowerCase() ===
                              checkArray[index].toLowerCase()
                              ? "input-bg-green "
                              : "input-bg-red"
                            : ""
                        }
                        type="text"
                        name={`input${index + 1}`}
                        maxLength="1"
                        value={
                          value !== null && value[index] ? value[index] : ""
                        }
                        onKeyDown={handleKeyDown}
                      />
                      <p className="text-white">
                        {generatedHints.includes(index) ? (
                          <>{checkArray[index].charCodeAt(0) - 96}</>
                        ) : (
                          <span className="opacity-0">--</span>
                        )}
                      </p>
                    </div>
                    {spacesCount.includes(index) && <br />}
                    {/* {getIndex(index)?.found === true &&
                    getIndex(index)?.index % 2 === 0 &&
                    getIndex(index)?.index !== 0 ? (
                      <br />
                    ) : null} */}
                    {/* {spacesCount.includes(index) ? <br /> : null} */}
                  </>
                ))}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Gameinput1;
