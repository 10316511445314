// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";
// import { getFirestore } from "firebase/firestore";
// import { getStorage } from "firebase/storage";

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAHLa0LDD9f7eLJ2neHnm48RvXPqhSHFKk",
//   authDomain: "numtease-5c6b2.firebaseapp.com",
//   projectId: "numtease-5c6b2",
//   storageBucket: "numtease-5c6b2.appspot.com",
//   messagingSenderId: "538319999544",
//   appId: "1:538319999544:web:cd84d8184c2cabdb738ccd",
//   measurementId: "G-CX8ZMVE06Z",
// };

// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
// firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });
// export const storage = getStorage(app);

// // const analytics = getAnalytics(app);

// export default firebase;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAHLa0LDD9f7eLJ2neHnm48RvXPqhSHFKk",
  authDomain: "numtease-5c6b2.firebaseapp.com",
  projectId: "numtease-5c6b2",
  storageBucket: "numtease-5c6b2.appspot.com",
  messagingSenderId: "538319999544",
  appId: "1:538319999544:web:cd84d8184c2cabdb738ccd",
  measurementId: "G-CX8ZMVE06Z",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
