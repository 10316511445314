import { useEffect, useRef, useState } from "react";
import "./App.css";
import Layout from "./components/Layout";
import axios from "axios";
import { db } from "./config/config";
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import { saveUser } from "./helpers/helpers";

function App() {
  const hasMounted = useRef(false);
  const [config, setConfig] = useState(null);
  const getUsers = async () => {
    const notesSnapshot = await getDocs(collection(db, "Configurations"));
    const notesList = notesSnapshot.docs.map((doc) => doc.data());

    setConfig(notesList[0]);
  };

  useEffect(() => {
    getUsers();
    saveUser();
  }, []);

  if (config === null) {
    return <>loading...</>;
  }

  return (
    <div>
      <Layout config={config} />
    </div>
  );
}

export default App;
