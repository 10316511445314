import React, { useEffect, useState } from "react";
import Gameinput1 from "../inputs/Gameinput1";

const AlphabetKeyboard = ({
  setShow,
  setCompleted,
  completed,
  setLifes,
  lost,
  hints,
  word,
  local,
  seconds,
  minutes,
  setLength,
}) => {
  const [checkArray, setCheckArray] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [shiftMode, setShiftMode] = useState(false);
  const [indexForClick, setIndexForClick] = useState(null);

  const handleButtonClick = (letter, pressed) => {
    console.log("triggered", pressed);
    if (letter === "Shift") {
      setShiftMode(!shiftMode);
      return;
    }
    if (letter === "BackSpace") {
      setInputValue("");
      return;
    }
    const finalLetter = shiftMode ? letter.toUpperCase() : letter.toLowerCase();

    if (indexForClick === null) {
      const foundIndex = inputValue.findIndex(
        (arrayElement) => arrayElement === ""
      );

      if (foundIndex !== -1) {
        if (
          letter.toLowerCase() !== checkArray[foundIndex].toLowerCase() &&
          pressed === true
        ) {
          console.log("subtracting two");
          setLifes((prevCount) => prevCount - 1);
        }
        const nextInput = document.getElementsByName(
          `input${foundIndex + 2}`
        )[0];
        nextInput?.focus();
        const newArray = [...inputValue];
        newArray[foundIndex] = letter;
        setInputValue(newArray);
      } else {
        console.log(`No matching element found in array`);
      }
    } else {
      const updatedArray = [...inputValue];
      updatedArray[indexForClick] = letter;
      setInputValue(updatedArray);
      setIndexForClick(null);
    }
  };

  const removeAlphabet = () => {
    if (indexForClick === null) {
      const foundIndex = inputValue.findIndex(
        (arrayElement) => arrayElement === ""
      );

      const nextInput = document.getElementsByName(`input${foundIndex}`)[0];
      nextInput?.focus();

      if (foundIndex !== -1) {
        const newArray = [...inputValue];
        newArray[foundIndex - 1] = "";
        setInputValue(newArray);
      } else {
        console.log(`No matching element found in array`);
      }
    } else {
      const updatedArray = [...inputValue];
      updatedArray[indexForClick] = "";
      setInputValue(updatedArray);
      setIndexForClick(null);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      removeAlphabet();
    }
  };

  const alphabet = "qwertyuiop".split("");
  const alphabet2 = "asdfghjkl".split("");
  const alphabet3 = "zxcvbnm".split("");

  return (
    <div className="alphabetKeyboard pb-5">
      <Gameinput1
        lost={lost}
        setLifes={setLifes}
        value={inputValue}
        handleKeyDown={handleKeyDown}
        setShow={setShow}
        setCompleted={setCompleted}
        setInputValue={setInputValue}
        setIndexForClick={setIndexForClick}
        handleButtonClick={handleButtonClick}
        completed={completed}
        hints={hints}
        word={word}
        local={local}
        seconds={seconds}
        minutes={minutes}
        removeAlphabet={removeAlphabet}
        checkArray={checkArray}
        setCheckArray={setCheckArray}
        setLength={setLength}
      />
      <div className="mx-auto keyboard-width">
        <div className="keyboard d-flex flex-wrap gap-1 gap-sm-2 justify-content-center w-full mb-2">
          {alphabet.map((letter) => (
            <button
              disabled={completed === true ? true : false}
              type="button"
              className="btn  rounded hg-buttons text-white fw-bold text-capitalize"
              key={letter}
              onClick={() => handleButtonClick(letter, true)}
            >
              {shiftMode ? letter.toUpperCase() : letter.toLowerCase()}
            </button>
          ))}
        </div>
        <div className="keyboard d-flex flex-wrap gap-1 gap-sm-2 justify-content-center w-full mb-2">
          {alphabet2.map((letter) => (
            <button
              disabled={completed === true ? true : false}
              type="button"
              className="btn  rounded hg-buttons text-white fw-bold text-capitalize"
              key={letter}
              onClick={() => handleButtonClick(letter, true)}
            >
              {shiftMode ? letter.toUpperCase() : letter.toLowerCase()}
            </button>
          ))}
        </div>
        <div className="keyboard d-flex flex-wrap gap-1 gap-sm-2 justify-content-center w-full ">
          <button
            disabled={completed === true ? true : false}
            onClick={() => handleButtonClick("Shift", true)}
            className="btn rounded hg-buttons big text-white fw-bold text-capitalize"
            type="button"
          >
            <img src="/images/iconup.png" alt="" />
          </button>
          {alphabet3.map((letter) => (
            <button
              disabled={completed === true ? true : false}
              type="button"
              className="btn rounded hg-buttons text-white fw-bold text-capitalize"
              key={letter}
              onClick={() => handleButtonClick(letter, true)}
            >
              {shiftMode ? letter.toUpperCase() : letter.toLowerCase()}
            </button>
          ))}
          <button
            disabled={completed === true ? true : false}
            className="btn rounded hg-buttons big text-white fw-bold text-capitalize"
            type="button"
            // onClick={() => setInputValue((prevValue) => prevValue.slice(0, -1))}
            onClick={removeAlphabet}
          >
            <img src="/images/iconback.png" alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlphabetKeyboard;
