import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { db } from "../config/config";
import { collection, addDoc } from "firebase/firestore";

const Timer = ({
  show,
  setShow,
  completed,
  howToModal,
  lost,
  setHints,
  setLifes,
  showClue,
  seconds,
  minutes,
  setSeconds,
  setMinutes,
  category,
  lifes,
  setHowToModal,
  setPause,
  pause,
  wordLength,
  hints,
}) => {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);
  const [linkshow, setlinkShow] = useState(false);
  const [sharebtnshow, setsharebtnShow] = useState(true);
  const [emailShared, SetEmailShared] = useState(false);
  const textRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlelinkshow = () => {
    setlinkShow(true);
    setsharebtnShow(false);
  };

  const copyToClipboard = () => {
    textRef.current.select();
    document.execCommand("copy");
  };

  const handleShare = () => {
    const shareData = {
      text: `I completed Numtease in 🕒 ${
        minutes < 10 ? "0" + minutes : minutes
      }:${
        seconds < 10 ? "0" + seconds : seconds
      } - can you beat that? -  www.numtease.com`,
    };

    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareData.text
    )}`;
    window.open(twitterUrl, "_blank");
  };

  const handleShareWhatsApp = () => {
    const shareData = {
      text: `I completed Numtease in 🕒 ${
        minutes < 10 ? "0" + minutes : minutes
      }:${
        seconds < 10 ? "0" + seconds : seconds
      } - can you beat that? -  www.numtease.com`,
    };

    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareData.text
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  let timer;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setInterval(() => {
      if (show !== true && howToModal === false && pause === false) {
        setSeconds(seconds + 1);
        if (seconds === 59) {
          setMinutes(minutes + 1);
          setSeconds(0);
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  const onShareEmail = async () => {
    if (email === null) {
      setError(true);
    } else {
      setError(false);
      const date = new Date();
      await addDoc(collection(db, "emails"), {
        email_address: email,
        date: date,
      });
      SetEmailShared(true);
      // handleClose();
    }
  };
  console.log("hints", wordLength, hints);
  return (
    <div>
      <div className="timer bg-black">
        <div className="d-flex flex-column align-items-center justify-content-center pt-3 ml-auto">
          {lost === true ? (
            <h5
              className="text-danger font-digital"
              style={{ textAlign: "center" }}
            >
              Unlucky, unfortunately you ran out of lives and were unable to
              complete today's Numtease. Try again tomorrow!
            </h5>
          ) : completed === true ? (
            <h5
              className="text-warn font-digital"
              style={{ textAlign: "center" }}
            >
              Well done, you have already completed today's Numtease
            </h5>
          ) : (
            <h1 className="text-warn">
              {minutes < 10 ? "0" + minutes : minutes}:
              {seconds < 10 ? "0" + seconds : seconds}
            </h1>
          )}
          {<h5 className="text-warn text-white">{lifes} lives Remaining</h5>}
          <div className="d-flex gap-3 mb-4">
            <button
              type="button"
              // onClick={handleShow}
              className="btn btn-dark fw-bold"
              style={{ backgroundColor: "#007fff" }}
            >
              {category}
            </button>
            {showClue === true && (
              <button
                onClick={() => {
                  setHints((prev) => prev + 1);
                  // setLifes((prev) => prev - 3);
                }}
                type="button"
                disabled={wordLength === hints}
                className="btn btn-dark fw-bold"
              >
                GIVE US A CLUE
              </button>
            )}
            <button
              type="button"
              onClick={() => {
                setHowToModal(true);
                setPause(true);
              }}
              className="btn btn-dark fw-bold"
            >
              HELP
            </button>
          </div>
        </div>
      </div>
      <Modal
        centered
        dialogClassName="rounded-3"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className=" text-white  bg curve-modal  ">
            <span
              className="bg-cover-2 rounded-4"
              style={{ backgroundImage: "url(/images/BG.png)" }}
            >
              <img
                className="d-block h-100 w-100"
                src="/images/Confetti.gif"
                alt=".."
              />
            </span>

            <div className="position-absolute pt-4 mt-3 top-0 end-0 start-0">
              <h2 className="text-center m-auto title mb-3 font fw-bold pt-3 ">
                CONGRATULATIONS
              </h2>
              <h1
                // ref={textRef}
                className="d-flex number mx-auto align-items-center justify-content-center"
              >
                {minutes < 10 ? "0" + minutes : minutes}:
                {seconds < 10 ? "0" + seconds : seconds}
              </h1>
            </div>

            <div className="d-flex flex-column m-auto align-items-center pt-2 ">
              <p
                style={{ textAlign: "center" }}
                className="text-white mb-0 m-3"
              >
                {" "}
                Want to know when tomorrow's game is ready to play? If so enter
                your email address and we will tell you as soon as it goes live.
              </p>

              <input
                disabled={emailShared === true ? true : false}
                style={{
                  width: "80%",
                  borderRadius: "5px",
                  padding: "4px",
                  marginTop: "5px",
                  "::placeholder": { color: "black" },
                  backgroundColor: "#ffc107",
                }}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address here..."
              />
              {error && <span className="text-danger">Please enter email</span>}

              <button
                disabled={emailShared === true ? true : false}
                onClick={onShareEmail}
                className="btn btn-warning mt-3"
              >
                {emailShared === true ? "Thanks!" : "Notify Me"}
              </button>
              <p
                style={{ textAlign: "center" }}
                className="text-white mb-0 m-3"
              >
                {" "}
                Share your score with friends on Twitter or WhatsApp by clicking
                these buttons
              </p>
              <div className="d-flex justify-content-center gap-3 my-4 w-100 px-3">
                {" "}
                <button
                  className="btn btn-icon btn-warning rounded-circle"
                  onClick={handleShare}
                >
                  <img
                    className="d-block h-100 w-100"
                    src="/images/twitter.svg"
                    alt="..."
                  />
                </button>
                <button
                  className="btn btn-icon btn-warning rounded-circle"
                  onClick={handleShareWhatsApp}
                >
                  <img
                    className="d-block h-100 w-100"
                    src="/images/whatsapp.svg"
                    alt="..."
                  />
                </button>
              </div>

              {linkshow && (
                <div>
                  <div className="d-flex flex-row pt-2">
                    <button className="button2">
                      <img src="/images/facebook.png" alt="" />
                    </button>
                    <button className="button2">
                      <img src="/images/instagram.png" alt="" />
                    </button>
                    <button className="button2">
                      <img src="/images/linkedin.png" alt="" />
                    </button>
                    <button className="button2">
                      <img src="/images/twitter1.png" alt="" />
                    </button>
                    <button className="button2">
                      <img src="/images/twitter2.png" alt="" />
                    </button>
                  </div>
                  <button
                    onClick={() => {
                      handleClose();
                      window.location.reload();
                    }}
                    className="text-warning d-flex m-auto my-4 px-5 py-2"
                  >
                    Play Again
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Timer;
