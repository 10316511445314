import axios from "axios";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../config/config";

const createNote = async (note) => {
  const noteSnapshot = await getDoc(doc(db, "userLogs", note?.id));
  if (noteSnapshot.exists()) {
    const date = new Date();
    const noteRef = doc(db, "userLogs", note.id);
    await updateDoc(noteRef, {
      logs: [...noteSnapshot.data().logs, date],
    });
  } else {
    await setDoc(doc(db, "userLogs", note.id), note);
  }
};

const createWinLog = async (ip, time) => {
  const date = new Date();
  await addDoc(collection(db, "winLogs"), {
    wonBy: ip,
    winDate: date,
    timeToWin: time,
  });
};

export const saveUser = () => {
  const today = new Date();
  axios.get("https://api.ipify.org?format=json").then((response) => {
    const ipAddress = response.data.ip;
    console.log("myIp", ipAddress);
    createNote({
      id: ipAddress,
      ip: ipAddress,
      logs: [today],
      last_visited_date: today,
    });
    // navigator.geolocation.getCurrentPosition(
    //   (position) => {
    //     const { latitude, longitude } = position.coords;

    //     axios
    //       .get(
    //         `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    //       )
    //       .then((response) => {
    //         const { country, state } = response.data.address;
    //         createNote({
    //           id: ipAddress,
    //           ip: ipAddress,
    //           country: country,
    //           state: state,
    //           logs: [today],
    //           last_visited_date: today,
    //         });
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   },
    //   (error) => {
    //     console.log("error", error);
    //     createNote({
    //       id: ipAddress,
    //       ip: ipAddress,
    //       logs: [today],
    //       last_visited_date: today,
    //     });

    //   }
    // );
  });
};

export const winLogAdd = (time) => {
  axios
    .get("https://api.ipify.org?format=json")
    .then((response) => {
      const ipAddress = response.data.ip;
      createWinLog(ipAddress, time);
    })
    .catch((err) => console.log(err));
};
